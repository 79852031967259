<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Títulos',
              quantity: counters.titles.total,
              icon: 'shield-alt',
              change: counters.titles.actives,
              changeVar: 'títulos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: counters.players.total,
              icon: 'users',
              change: counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entrenadores',
              quantity: counters.coaches.total,
              icon: 'chalkboard-teacher',
              change: counters.coaches.actives,
              changeVar: 'entrenadores activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: counters.categories.total,
              icon: 'chess',
              change: counters.categories.actives,
              changeVar: 'categorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row items-center">
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.type"
              :options="types"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>

          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.year"
              :options="[{id: 2021, name: '2021'},{id: 2022, name: '2022'},{id: 2023, name: '2023'},{id: 2024, name: '2024'}]"
              placeholder="Año"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          variant="warning"
          class="my-auto mr-2 h-fit py-2 lg:text-sm xl:text-md"
          @click="$router.push('/academias/'+$route.params.id+'/categorias/'+$route.params.id2+'/titulos/crear')"
        >
          Añadir título
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="otherTitle"
          :headers="headers"
          :url="'academias/'+$route.params.id+'/categorias/'+$route.params.id2+'/titulos'"
          :data="titles"
          :route="'master/title/type/'+parameters.type"
          :tableName="'TÍTULOS'"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
export default {
  components: {
    StatCard,
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Título",
        "Galardonado",
        "Año",
        "Tipo",
        "Acciones"
      ],
      counters: {
        players: {},
        coaches: {},
        categories: {},
        titles: {}
      },
      parameters: {
        order_key: 'year',
        order_vaue: 'desc',
        type: 1,
        id_academy: this.$route.params.id,
        id_category: null,
        id_subcategory: null,
        year: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    types() {
      return [
        {
          id: 1,
          name: "Academia"
        },
        {
          id: 2,
          name: "Entrenador"
        },
        {
          id: 3,
          name: "Jugador"
        }
      ]
    },
    titles() {
      return this.$store.getters["global/getItems"]("otherTitle", "elements").map(
        (el) => {
          if (this.parameters.type == 1) {
            return {
              id: el.id,
              image: el.title ? el.title.url_picture : "",
              title: el.title ? el.title.name : "",
              name: el.academy ? el.academy.name : "",
              year: el.year,
              type: "Academia",
              active: el.active,
            }
          }
          else if (this.parameters.type == 2) {
            return {
              id: el.id,
              image: el.coach ? el.coach.url_profile_picture : "",
              title: el.title ? el.title.name : "",
              name: el.coach ? el.coach.first_name + " " + el.coach.last_name : "",
              year: el.year,
              type: "Entrenador",
              active: el.active,
            }
          }
          else {
            return {
              id: el.id,
              image: el.player ? el.player.url_profile_picture : "",
              title: el.title ? el.title.name : "",
              name: el.player ? el.player.first_name + " " + el.player.last_name : "",
              year: el.year,
              type: "Jugador",
              active: el.active,
            }
          }
        }
      )
    },
  },
  methods: {
    getAcademyCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/academy/"+this.$route.params.id+"/counters",
          noPaginate: true
        })
        .then((response) => {
          this.counters = response.data.counters
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: 'academy_category',
          module: 'academyCategory',
          id: this.$route.params.id2
        })
        .then((response) => {
          this.parameters.id_academy = response.data.id_academy
          this.parameters.id_category = response.data.id_category
          this.parameters.id_subcategory = response.data.id_subcategory
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.counters.titles.actives--
    },
    activate() {
      this.counters.titles.actives++
    },
    erase() {
      this.counters.titles.total--
      this.counters.titles.actives--
    }
  },
  created() {
    this.getAcademyCounters()
    this.getItem()
  }
}
</script>
